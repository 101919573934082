module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://icd.wordsinspace.net/graphql","protocol":"https","schema":{"timeout":300000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"verboseOutput":true,"html":{"createStaticFiles":false,"useGatsbyImage":false,"imageQuality":60,"imageMaxWidth":1400,"fallbackImageMaxWidth":1024,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"develop":{"nodeUpdateInterval":5000,"hardCacheMediaFiles":false,"hardCacheData":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false,"allow401Images":false},"excludeFieldNames":["generalSettings","email","allSettings","generalSettingsEmail","viewer","pinged","toPing"],"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":true,"panicOnError":true,"writeQueriesToDisk":false,"onlyReportCriticalErrors":false,"copyQueryOnError":false,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"RootQuery":{"excludeFieldNames":["generalSettings","email","allSettings","generalSettingsEmail","viewer","pinged","toPing"]},"Comment":{"limit":0},"Post":{"excludeFieldNames":["pinged","toPing","isSticky"],"limit":null},"Page":{"excludeFieldNames":["pinged","toPing"],"limit":null},"MediaItem":{"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false}},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/work/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"06b6d3a28c7c247d667a3c6a219b34d0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"desktop":"(min-width: 1024px)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
